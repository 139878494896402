import type React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  useVitaTracking,
  useVitaHost,
  useVitaReactiveVariableActions,
} from '@xing-com/crate-lebenslauf-hooks';
import { useViewerData } from '@xing-com/hub';
import { usePopOver } from '@xing-com/pop-over';
import { ProfileImage } from '@xing-com/profile-image';

import {
  StyledLoginButton,
  StyledProfileMenu,
  StyledMenu,
  StyledLink,
  StyledSkeletonButton,
} from './login.styled';

type LoginProps = {
  isOnProgressBar?: boolean;
  className?: string;
};

export const Login: React.FC<LoginProps> = ({ isOnProgressBar, className }) => {
  const { data, loading } = useViewerData();
  const { loginUrl, logoutUrl } = useVitaHost();
  const { trackInteraction, trackWithNWT } = useVitaTracking({
    pageName: 'editor',
  });
  const isLoggedIn = data?.loginState === 'LOGGED_IN';
  const popOver = usePopOver();
  const { deleteLoggedInData } = useVitaReactiveVariableActions();

  if (loading) {
    return (
      <StyledSkeletonButton
        size="small"
        sizeConfined="medium"
        $isOnProgressBar={isOnProgressBar}
        className={className}
      />
    );
  }

  return (
    <span className={className}>
      {isLoggedIn ? (
        <>
          <StyledProfileMenu
            onClick={popOver.togglePopOver}
            data-qa="profile-menu"
            $isOnProgressBar={isOnProgressBar}
          >
            <ProfileImage
              profileName={data.user?.displayName}
              size="xsmall"
              sizeConfined="small"
              src={data.user?.profileImage[0].url}
            />
          </StyledProfileMenu>
          <StyledMenu
            $isOnProgressBar={isOnProgressBar}
            dimmerTitle="Close menu"
            onOutsideClick={popOver.handleHide}
            show={popOver.show}
            triggerRef={popOver.triggerRef}
          >
            <StyledLink
              href="https://www.xing.com/profile"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="CRATE_LEBENSLAUF_LOGIN_PROFILE" />
            </StyledLink>
            <StyledLink
              href="https://faq.xing.com"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="CRATE_LEBENSLAUF_LOGIN_HELP" />
            </StyledLink>
            <StyledLink
              href={logoutUrl}
              data-qa="lebenslauf_logout"
              onClick={async () => {
                trackInteraction('lebenslauf_editor_logout_click', {
                  event: 'EventLogout',
                  EventLogout: 1,
                });
                trackWithNWT({
                  event: 'clicked',
                  element: 'topnav_dropdown_logout',
                });
                deleteLoggedInData();
                await new Promise((resolve) => setTimeout(resolve, 100));
                if (window) window.location.reload();
              }}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="CRATE_LEBENSLAUF_LOGIN_LOGOUT" />
            </StyledLink>
          </StyledMenu>
        </>
      ) : (
        <StyledLoginButton
          variant="secondary"
          data-qa="login-button"
          onClick={() => {
            trackInteraction('lebenslauf_editor_login_click');
            trackWithNWT({
              event: 'opened',
              element: 'topnav_login',
            });
          }}
          href={loginUrl}
          size="small"
          $isOnProgressBar={isOnProgressBar}
        >
          <FormattedMessage id="CRATE_LEBENSLAUF_LOGIN_BUTTON" />
        </StyledLoginButton>
      )}
    </span>
  );
};
