import { useLocation } from '@reach/router';
import * as React from 'react';

import {
  type InteractionTrackingEvent,
  type NWTEvent,
  type PageViewTrackingEvent,
  useTracking,
} from '@xing-com/crate-communication-tracking';
import { useViewerData } from '@xing-com/hub';

import { useHostName } from './use-host-name';
import { useIsCoverLetterEditor } from './use-is-cover-letter-editor';

export type AdobeTrackingEvent<T = Record<string, unknown>> =
  InteractionTrackingEvent<T>;

type OptionalPageviewTrackingParams = {
  PropContextDimension1?: string;
  EventNewsArticleView?: number | string;
  PropNewsProduct?: string;
  PropNewsPublisherId?: string;
  PropNewsArticleId?: string;
  PropExperiment?: string;
  PropExperimentInfo?: string;
};

type OptionalNWTTrackingParams = {
  event?: string;
  context?: string;
  element?: string;
  elementDetail?: string;
  page?: string;
  flags?: string;
  sentBy?: string;
  position?: number;
  itemUrn?: string;
  itemActorUrn?: string;
};

type OverrideAdobeData = {
  trackAction?: string;
  pageName?: string;
};

export type OptionalAdobeTrackingParams = {
  PropContextDimension1?: string;
  PropContextDimension2?: string;
  PropContextDimension4?: string;
  PropContextDimension5?: string;
  PropList?: string[];
  EventNewsArticleView?: string;
  PropActionOrigin?: string;
  EventLogout?: number;
  event?: string;
};

type AdobeData = OverrideAdobeData & OptionalAdobeTrackingParams;

type UseVitaTrackingProps = OverrideAdobeData;

type UseVitaTrackingReturn = {
  trackPageView: (extraData?: OptionalPageviewTrackingParams) => void;
  trackWithNWT: (extraData?: OptionalNWTTrackingParams) => void;
  trackInteraction: (
    overrideTrackAction?: string,
    extraData?: Omit<AdobeData, 'trackAction'>
  ) => void;
  trackScrolling: (
    propScrolling: string,
    extraData?: Omit<AdobeData, 'trackAction'>
  ) => void;
  getAdobeTrackedUrl: (url: string, optionalParams?: AdobeData) => string;
  getTrackingPrefixes: () => {
    host: string;
    cover_letter: string;
    cv: string;
  };
};

export type AdobePropTrackActionEvent = AdobeTrackingEvent<
  {
    type: 'interaction';
    event: 'PropTrackAction' | 'PropScrolling';
    PropTrackAction: string;
  } & Record<string, unknown>
>;

export const getAdobePropTrackActionEvent = (
  trackActionProp: string,
  options?: Record<string, unknown>
): AdobePropTrackActionEvent => ({
  type: 'interaction',
  event: 'PropTrackAction',
  PropTrackAction: trackActionProp,
  ...options,
});

export const useVitaTracking = ({
  trackAction,
  pageName,
}: UseVitaTrackingProps = {}): UseVitaTrackingReturn => {
  const { track } = useTracking<
    AdobeTrackingEvent | PageViewTrackingEvent | NWTEvent
  >();
  const userId = useViewerData().data?.user?.id;
  const hostName = useHostName();
  const isCoverLetterEditor = useIsCoverLetterEditor();
  const location = useLocation();
  const isCvEditor = location.pathname.includes('neu');
  const isXingHost = hostName.includes('xing');
  const isLebenslaufHost = hostName.includes('lebenslauf');
  const isAnschreibenHost = hostName.includes('anschreiben');
  const host = isAnschreibenHost ? 'anschreiben' : 'lebenslauf';

  const getApplicationPrefix = (): string => {
    if (isAnschreibenHost) return 'ans';
    if (isXingHost) return 'wbm';
    if (isLebenslaufHost) return 'leb';
    else return 'unknown';
  };

  const getChannel = (): string => prefix + '/' + host;

  const prefix = getApplicationPrefix();
  const channel = getChannel();
  let page = `${channel}/${pageName}`;

  if (isAnschreibenHost && isCvEditor) {
    page = `${channel}/lebenslauf/${pageName}`;
  }

  const PropActionOrigin = `lebenslauf_${pageName}_${prefix}`;

  const trackPageView = React.useCallback<
    UseVitaTrackingReturn['trackPageView']
  >(
    (extraData) => {
      track({ type: 'pageview', channel, page, ...extraData });
    },
    [track, channel, page]
  );

  const trackWithNWT = React.useCallback<UseVitaTrackingReturn['trackWithNWT']>(
    (extraData) => {
      const application = prefix;

      let page = `${host}/${pageName}`;

      if (isAnschreibenHost && isCvEditor) {
        page = `${host}/lebenslauf/${pageName}`;
      }

      if (!isAnschreibenHost && isCoverLetterEditor) {
        page = `${host}/anschreiben/${pageName}`;
      }

      track({
        type: 'nwt',
        event: 'viewed_screen',
        eventSchema: 'basic',
        schemaVersion: '1.17.0',
        sentBy: host,
        channel,
        application,
        page,
        userId,
        ...extraData,
      });
    },
    [channel, pageName, track, prefix, userId]
  );

  const trackInteraction = React.useCallback<
    UseVitaTrackingReturn['trackInteraction']
  >(
    (overrideTrackAction, extraData) => {
      const sentTrackAction = overrideTrackAction ?? trackAction;
      const event =
        sentTrackAction &&
        getAdobePropTrackActionEvent(sentTrackAction, { ...extraData });

      if (event) track(event);
    },
    [track, trackAction]
  );

  const trackScrolling = React.useCallback<
    UseVitaTrackingReturn['trackScrolling']
  >(
    (propScrolling, extraData) => {
      track({
        type: 'interaction',
        event: 'PropScrolling',
        ...(propScrolling && { PropScrolling: propScrolling }),
        ...extraData,
      });
    },
    [track]
  );

  const getAdobeTrackedUrl = React.useCallback(
    (url: string, optionalParams?: AdobeData): string => {
      const {
        trackAction: overrideTrackAction,
        PropActionOrigin: overridePropActionOrigin,
        ...optionalParamsRest
      } = optionalParams ?? {};

      const usedTrackAction = overrideTrackAction ?? trackAction;
      const usedPropActionOrigin = overridePropActionOrigin ?? PropActionOrigin;

      return usedTrackAction
        ? getAdobeTrackingUrl(url, usedTrackAction, {
            PropActionOrigin: usedPropActionOrigin,
            ...optionalParamsRest,
          })
        : url;
    },
    [trackAction, PropActionOrigin]
  );

  const getAdobeTrackingUrl = (
    originalUrl: string,
    trackAction: string,
    optionalParams?: OptionalAdobeTrackingParams
  ): string => {
    const { PropActionOrigin } = optionalParams ?? {};
    const trackingParams = [
      `sc_o=${trackAction}`,
      PropActionOrigin && `sc_o_PropActionOrigin=${PropActionOrigin}`,
    ].filter((param): param is string => !!param);
    const urlHasQueryString = originalUrl.includes('?');
    const separator = urlHasQueryString ? '&' : '?';

    return `${originalUrl}${separator}${trackingParams.join('&')}`;
  };

  const getTrackingPrefixes = (): {
    host: string;
    cover_letter: string;
    cv: string;
  } => {
    const prefixes = {
      host: isAnschreibenHost ? 'anschreiben' : 'lebenslauf',
      cover_letter: isAnschreibenHost
        ? 'anschreiben'
        : 'lebenslauf_anschreiben',
      cv: isAnschreibenHost ? 'anschreiben_lebenslauf' : 'lebenslauf',
    };

    return prefixes;
  };

  return {
    getTrackingPrefixes,
    trackPageView,
    trackInteraction,
    trackScrolling,
    trackWithNWT,
    getAdobeTrackedUrl,
  };
};
