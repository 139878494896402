import { useRuntimeConfig } from '@xing-com/crate-xinglet/internal';

export const useIsCrateApp = (): boolean => {
  const { entryPoint } = useRuntimeConfig();

  return (
    entryPoint !== '@xing-com/crate-lebenslauf-root' &&
    entryPoint !== '@xing-com/crate-lebenslauf-anschreiben'
  );
};
